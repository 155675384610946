import MUIButton from "@mui/material/Button";

import styles from "./index.module.scss";

export default function TextButton(props) {
  return (
    <MUIButton
      {...props}
      variant="text"
      className={`${styles.btn} ${props.className} 
      ${props.fullWidth ? styles.fullWidth : ""}
      ${props.secondary ? styles.secondary : ""}
      `}
    >
      {props.children}
    </MUIButton>
  );
}
