import {
  AppBar,
  Box,
  Container,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import Button from "../components/Button/Button";
import NavLogo from "../components/atoms/NavLogo";
import TextButton from "../components/TextButton";
import { Book, Euro, HowToReg, Login, Menu } from "@mui/icons-material";
import { useState } from "react";
import Link from "next/link";
import { useExperiment } from "lib/experiments";
import analytics from "config/analytics";
import UptimelineLogo from "components/Logo/logo";
import UptimelineLogoLight from "components/Logo/logoLight";
import { useAppState } from "lib/appState";

export default function WebsiteV2({
  children,
  minimumLinks = false,
  ...props
}) {
  const [appState, setAppState] = useAppState();
  const paymentsVariant = useExperiment("p") || "a";
  const forceTrial = false;

  const [drawer, setDrawer] = useState(false);
  return (
    <>
      <AppBar
        sx={{
          height: {
            xs: "64px",
            md: "110px",
          },
          boxShadow: "none",
          backgroundColor: "white.main",
          color: "unset",
        }}
      >
        <Container
          maxWidth="xl"
          {...props}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            ...(props.sx || {}),
          }}
        >
          <Link href="/">
            <UptimelineLogoLight
              style={{
                height: 31,
                cursor: "pointer",
              }}
            />
          </Link>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {!minimumLinks && (
              <>
                <Link href="/#pricing">
                  <TextButton>pricing</TextButton>
                </Link>
                <Link href="https://blog.uptimeline.com/">
                  <TextButton>blog</TextButton>
                </Link>
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {appState.user ? (
              <>
                <Link href="/dashboard">
                  <Button gradient>Dashboard</Button>
                </Link>
              </>
            ) : (
              <>
                <Link href="/auth/login">
                  <TextButton>Log In</TextButton>
                </Link>
                <Link
                  href={
                    forceTrial
                      ? process.env.NEXT_PUBLIC_START_TRIAL_LINK ||
                        "/auth/register"
                      : "/auth/register"
                  }
                  onClick={
                    forceTrial
                      ? analytics.beginCheckoutFreeTrialWithCardEvent
                      : undefined
                  }
                >
                  <Button gradient>
                    {forceTrial ? "Try free" : "Register"}
                  </Button>
                </Link>
              </>
            )}
          </Stack>
          <IconButton
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
            size="large"
            aria-label="menu"
            onClick={() => setDrawer(true)}
          >
            <Menu fontSize="inherit" />
          </IconButton>
        </Container>
      </AppBar>
      <SwipeableDrawer
        anchor="bottom"
        open={drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
      >
        {[
          {
            text: "Log In",
            icon: Login,
            href: "/auth/login",
          },
          {
            text: forceTrial ? "Try free" : "Sign up",
            icon: HowToReg,
            href: forceTrial
              ? process.env.NEXT_PUBLIC_START_TRIAL_LINK || "/auth/register"
              : "/auth/register",
            onClick: forceTrial
              ? analytics.beginCheckoutFreeTrialWithCardEvent
              : undefined,
          },

          ...(minimumLinks
            ? []
            : [
                {
                  text: "Pricing",
                  icon: Euro,
                  href: "/#pricing",
                },
                {
                  text: "Blog",
                  icon: Book,
                  href: "https://blog.uptimeline.com/",
                },
              ]),
        ].map(({ text, icon: Icon, href, onClick }) => (
          <ListItem disablePadding>
            <Link href={href} onClick={onClick}>
              <ListItemButton>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </SwipeableDrawer>
      <Container
        sx={{
          minHeight: "100vh",
          paddingTop: "110px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ height: "50px" }}></Box>
        {children}
        <Box sx={{ height: 200, flex: 1 }}></Box>
        {!minimumLinks && (
          <>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              spacing={{
                xs: 0,
                md: 2,
              }}
            >
              <Link href="https://blog.uptimeline.com/">
                <TextButton>Blog</TextButton>
              </Link>
              <Link href="/terms">
                <TextButton>Terms and conditions</TextButton>
              </Link>
              <Link href="/privacy">
                <TextButton>Privacy policy</TextButton>
              </Link>
              <Link href="mailto:info@uptimeline.com">
                <TextButton>Contact us</TextButton>
              </Link>
            </Stack>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              spacing={{
                xs: 0,
                md: 2,
              }}
              sx={{ alignItems: "center", marginTop: 4 }}
            >
              <Typography>Uptimeline is made in</Typography>
              <Typography sx={{ color: "#139a43" }}>Tenerife, Spain</Typography>
              <Typography sx={{ color: "#138f61" }}>Berlin, Germany</Typography>
              <Typography sx={{ color: "#13857d" }}>Turin, Italy</Typography>
              <Typography sx={{ color: "#137b9a" }}>
                Bucharest, Romania
              </Typography>
            </Stack>
          </>
        )}
        <Typography
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: "2rem",
            fontFamily: "'Open Sans', sans-serif",
            marginTop: 8,
            marginBottom: 8,
            opacity: 0.1,
          }}
        >
          Uptimeline
        </Typography>
      </Container>
    </>
  );
}
