export default function UptimelineLogoLight(props) {
  return (
    <svg
      width="170"
      height="65"
      viewBox="0 0 356 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="13"
        width="356"
        height="7"
        rx="3.5"
        fill="#139A43"
        fill-opacity="0.5"
      />
      <path
        d="M42.9375 3.3125V32.875C42.9375 36.0208 42.2396 38.8646 40.8438 41.4062C39.4688 43.9271 37.375 45.9271 34.5625 47.4062C31.7708 48.8854 28.25 49.625 24 49.625C17.9583 49.625 13.3542 48.0833 10.1875 45C7.02083 41.9167 5.4375 37.8333 5.4375 32.75V3.3125H15.0938V31.2812C15.0938 35.0521 15.8646 37.6979 17.4062 39.2188C18.9479 40.7396 21.2292 41.5 24.25 41.5C26.375 41.5 28.0938 41.1354 29.4062 40.4062C30.7396 39.6771 31.7188 38.5521 32.3438 37.0312C32.9688 35.5104 33.2812 33.5729 33.2812 31.2188V3.3125H42.9375Z"
        fill="#282B28"
      />
      <path
        d="M72.8125 13.4062C76.75 13.4062 79.9271 14.9375 82.3438 18C84.7812 21.0625 86 25.5521 86 31.4688C86 35.4271 85.4271 38.7604 84.2812 41.4688C83.1354 44.1562 81.5521 46.1875 79.5312 47.5625C77.5104 48.9375 75.1875 49.625 72.5625 49.625C70.875 49.625 69.4271 49.4167 68.2188 49C67.0104 48.5625 65.9792 48.0104 65.125 47.3438C64.2708 46.6562 63.5312 45.9271 62.9062 45.1562H62.4062C62.5729 45.9896 62.6979 46.8438 62.7812 47.7188C62.8646 48.5938 62.9062 49.4479 62.9062 50.2812V64.375H53.375V14.0625H61.125L62.4688 18.5938H62.9062C63.5312 17.6562 64.2917 16.7917 65.1875 16C66.0833 15.2083 67.1562 14.5833 68.4062 14.125C69.6771 13.6458 71.1458 13.4062 72.8125 13.4062ZM69.75 21.0312C68.0833 21.0312 66.7604 21.375 65.7812 22.0625C64.8021 22.75 64.0833 23.7812 63.625 25.1562C63.1875 26.5312 62.9479 28.2708 62.9062 30.375V31.4062C62.9062 33.6562 63.1146 35.5625 63.5312 37.125C63.9688 38.6875 64.6875 39.875 65.6875 40.6875C66.7083 41.5 68.1042 41.9062 69.875 41.9062C71.3333 41.9062 72.5312 41.5 73.4688 40.6875C74.4062 39.875 75.1042 38.6875 75.5625 37.125C76.0417 35.5417 76.2812 33.6146 76.2812 31.3438C76.2812 27.9271 75.75 25.3542 74.6875 23.625C73.625 21.8958 71.9792 21.0312 69.75 21.0312Z"
        fill="#282B28"
      />
      <path
        d="M108.594 42.0312C109.635 42.0312 110.646 41.9271 111.625 41.7188C112.625 41.5104 113.615 41.25 114.594 40.9375V48.0312C113.573 48.4896 112.302 48.8646 110.781 49.1562C109.281 49.4688 107.635 49.625 105.844 49.625C103.76 49.625 101.885 49.2917 100.219 48.625C98.5729 47.9375 97.2708 46.7604 96.3125 45.0938C95.375 43.4062 94.9062 41.0625 94.9062 38.0625V21.2188H90.3438V17.1875L95.5938 14L98.3438 6.625H104.438V14.0625H114.219V21.2188H104.438V38.0625C104.438 39.3958 104.812 40.3958 105.562 41.0625C106.333 41.7083 107.344 42.0312 108.594 42.0312Z"
        fill="#282B28"
      />
      <path
        d="M131.219 14.0625V49H121.688V14.0625H131.219ZM126.469 0.375C127.885 0.375 129.104 0.708333 130.125 1.375C131.146 2.02083 131.656 3.23958 131.656 5.03125C131.656 6.80208 131.146 8.03125 130.125 8.71875C129.104 9.38542 127.885 9.71875 126.469 9.71875C125.031 9.71875 123.802 9.38542 122.781 8.71875C121.781 8.03125 121.281 6.80208 121.281 5.03125C121.281 3.23958 121.781 2.02083 122.781 1.375C123.802 0.708333 125.031 0.375 126.469 0.375Z"
        fill="#282B28"
      />
      <path
        d="M182.281 13.4062C186.24 13.4062 189.229 14.4271 191.25 16.4688C193.292 18.4896 194.312 21.7396 194.312 26.2188V49H184.75V28.5938C184.75 26.0938 184.323 24.2083 183.469 22.9375C182.615 21.6667 181.292 21.0312 179.5 21.0312C176.979 21.0312 175.188 21.9375 174.125 23.75C173.062 25.5417 172.531 28.1146 172.531 31.4688V49H163V28.5938C163 26.9271 162.812 25.5312 162.438 24.4062C162.062 23.2812 161.49 22.4375 160.719 21.875C159.948 21.3125 158.958 21.0312 157.75 21.0312C155.979 21.0312 154.583 21.4792 153.562 22.375C152.562 23.25 151.844 24.5521 151.406 26.2812C150.99 27.9896 150.781 30.0833 150.781 32.5625V49H141.25V14.0625H148.531L149.812 18.5312H150.344C151.052 17.3229 151.938 16.3438 153 15.5938C154.083 14.8438 155.271 14.2917 156.562 13.9375C157.854 13.5833 159.167 13.4062 160.5 13.4062C163.062 13.4062 165.229 13.8229 167 14.6562C168.792 15.4896 170.167 16.7812 171.125 18.5312H171.969C173.01 16.7396 174.479 15.4375 176.375 14.625C178.292 13.8125 180.26 13.4062 182.281 13.4062Z"
        fill="#282B28"
      />
      <path
        d="M218.531 13.4062C221.76 13.4062 224.542 14.0312 226.875 15.2812C229.208 16.5104 231.01 18.3021 232.281 20.6562C233.552 23.0104 234.188 25.8854 234.188 29.2812V33.9062H211.656C211.76 36.5938 212.562 38.7083 214.062 40.25C215.583 41.7708 217.688 42.5312 220.375 42.5312C222.604 42.5312 224.646 42.3021 226.5 41.8438C228.354 41.3854 230.26 40.6979 232.219 39.7812V47.1562C230.49 48.0104 228.677 48.6354 226.781 49.0312C224.906 49.4271 222.625 49.625 219.938 49.625C216.438 49.625 213.333 48.9792 210.625 47.6875C207.938 46.3958 205.823 44.4271 204.281 41.7812C202.76 39.1354 202 35.8021 202 31.7812C202 27.6979 202.688 24.3021 204.062 21.5938C205.458 18.8646 207.396 16.8229 209.875 15.4688C212.354 14.0938 215.24 13.4062 218.531 13.4062ZM218.594 20.1875C216.74 20.1875 215.198 20.7812 213.969 21.9688C212.76 23.1562 212.062 25.0208 211.875 27.5625H225.25C225.229 26.1458 224.969 24.8854 224.469 23.7812C223.99 22.6771 223.26 21.8021 222.281 21.1562C221.323 20.5104 220.094 20.1875 218.594 20.1875Z"
        fill="#282B28"
      />
      <path d="M251.469 49H241.938V0.375H251.469V49Z" fill="#282B28" />
      <path
        d="M271.031 14.0625V49H261.5V14.0625H271.031ZM266.281 0.375C267.698 0.375 268.917 0.708333 269.938 1.375C270.958 2.02083 271.469 3.23958 271.469 5.03125C271.469 6.80208 270.958 8.03125 269.938 8.71875C268.917 9.38542 267.698 9.71875 266.281 9.71875C264.844 9.71875 263.615 9.38542 262.594 8.71875C261.594 8.03125 261.094 6.80208 261.094 5.03125C261.094 3.23958 261.594 2.02083 262.594 1.375C263.615 0.708333 264.844 0.375 266.281 0.375Z"
        fill="#282B28"
      />
      <path
        d="M300.906 13.4062C304.635 13.4062 307.635 14.4271 309.906 16.4688C312.177 18.4896 313.312 21.7396 313.312 26.2188V49H303.781V28.5938C303.781 26.0938 303.323 24.2083 302.406 22.9375C301.51 21.6667 300.094 21.0312 298.156 21.0312C295.24 21.0312 293.25 22.0208 292.188 24C291.125 25.9792 290.594 28.8333 290.594 32.5625V49H281.062V14.0625H288.344L289.625 18.5312H290.156C290.906 17.3229 291.833 16.3438 292.938 15.5938C294.062 14.8438 295.302 14.2917 296.656 13.9375C298.031 13.5833 299.448 13.4062 300.906 13.4062Z"
        fill="#282B28"
      />
      <path
        d="M337.531 13.4062C340.76 13.4062 343.542 14.0312 345.875 15.2812C348.208 16.5104 350.01 18.3021 351.281 20.6562C352.552 23.0104 353.188 25.8854 353.188 29.2812V33.9062H330.656C330.76 36.5938 331.562 38.7083 333.062 40.25C334.583 41.7708 336.688 42.5312 339.375 42.5312C341.604 42.5312 343.646 42.3021 345.5 41.8438C347.354 41.3854 349.26 40.6979 351.219 39.7812V47.1562C349.49 48.0104 347.677 48.6354 345.781 49.0312C343.906 49.4271 341.625 49.625 338.938 49.625C335.438 49.625 332.333 48.9792 329.625 47.6875C326.938 46.3958 324.823 44.4271 323.281 41.7812C321.76 39.1354 321 35.8021 321 31.7812C321 27.6979 321.688 24.3021 323.062 21.5938C324.458 18.8646 326.396 16.8229 328.875 15.4688C331.354 14.0938 334.24 13.4062 337.531 13.4062ZM337.594 20.1875C335.74 20.1875 334.198 20.7812 332.969 21.9688C331.76 23.1562 331.062 25.0208 330.875 27.5625H344.25C344.229 26.1458 343.969 24.8854 343.469 23.7812C342.99 22.6771 342.26 21.8021 341.281 21.1562C340.323 20.5104 339.094 20.1875 337.594 20.1875Z"
        fill="#282B28"
      />
    </svg>
  );
}
