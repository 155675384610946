const generateEvent = ({ adsEventId }) =>
  function ({ data = {} } = {}) {
    try {
      return global.gtag("event", "conversion", {
        send_to: `AW-314317962/${adsEventId}`,
        transport_type: "beacon",
        ...data,
      });
    } catch (e) {}
  };

const analytics = {
  createMonitorEvent: generateEvent({ adsEventId: "tI5sCICmiMwDEIq58JUB" }),
  createSecondMonitorEvent: generateEvent({
    adsEventId: "ZhxbCKOsuMwDEIq58JUB",
  }),
  createFifthMonitorEvent: generateEvent({
    adsEventId: "ow7QCP7PgMsDEIq58JUB",
  }),
  beginCheckoutFreeTrialWithCardEvent: generateEvent({
    adsEventId: "5Yo1CLia2NEDEIq58JUB",
  }),
  subscribeFreeTrialEvent: generateEvent({
    adsEventId: "CtgVCLua2NEDEIq58JUB",
  }),
  linkWithEvent: (event, path, router) => async (e) => {
    e.preventDefault();
    await event();
    router.push(path);
  },
};

export default analytics;
