import Link from "next/link";

export default function NavLogo({ dark = false }) {
  return (
    <Link href="/">
      <a
        className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="#"
      >
        <img
          src={`/img/logo${dark ? "_dark" : ""}.svg`}
          alt="UptimeLine logo"
          className="h-8"
        />
      </a>
    </Link>
  );
}
